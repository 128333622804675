<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="250"
    content-class="user-profile-menu-content"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="avatar"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list dense nav>
      <div class="pa-3 pt-2">
        <v-avatar size="40px">
          <v-img :src="avatar"></v-img>
        </v-avatar>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold">
            {{ account }}
          </span>
          <small><b>ID:</b> {{ usertId }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item link>
        <v-list-item-icon class="me-2 pt-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-5">
          <router-link to="/profile">
            <v-list-item-title>Profile</v-list-item-title>
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2 pt-1">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/signin">
            <v-list-item-title>Logout</v-list-item-title>
          </router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import { LOAD_ORGANIZATION } from "@/store/_actiontypes";
import { mdiAccountOutline, mdiLogoutVariant } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
      },
      usertId: "",
      account: "",
      avatar: "",
    }
  },

  created() {
    this.loadMenuProfile();
  },

  methods: {
    ...mapActions("account", [LOAD_ORGANIZATION]),

    loadMenuProfile(){
      let data = this.$tools.getLoggedInUserInfo();
      this.usertId = data.sub.replaceAll('-', '');
      this.account = data.full_name;
      this.avatar = data.photo != "" && data.photo != null
        ? this.$tools.getEnv("VUE_APP_FILE_SERVER") + data.photo
        : this.$tools.defaultImage();
    },
  },

}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
